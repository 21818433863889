import React from 'react'
import '../Searching.css'
import {TransparentButton} from "../../../TransparentButton/TransparentButton";

function SearchByWord({searchByKeyWord, onShowAll, changeSetNum, changeCurrentPage, changeActivePage, update, keyWord, setKeyWord}) {

    function handleSearchByKeyWord(e) {
        e.preventDefault()
        searchByKeyWord(keyWord)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }

    function showAll() {
        onShowAll()
        setKeyWord('')
    }

    function handleInputChange(e) {
        setKeyWord(e.target.value);
        if (!(e.target.value.length)) {
            showAll()
            changeSetNum(1)
            changeCurrentPage(0)
            changeActivePage(1)
        }
        searchByKeyWord(e.target.value)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            searchByKeyWord(keyWord);
            changeSetNum(1)
            changeCurrentPage(0)
            changeActivePage(1)
        }
    }

    return (
        <div className='searching__container'>
            <div>
                <input
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    className='searching__input'
                    placeholder='Введите слово для поиска'
                    value={keyWord}
                    type="text"
                />
            </div>
            <TransparentButton onClick={handleSearchByKeyWord} className='searching__btn' type="button">Найти</TransparentButton>
            <TransparentButton onClick={showAll} className='searching__btn' type="button">Показать все</TransparentButton>
            <TransparentButton className='searching__btn' onClick={update}>Обновить</TransparentButton>
        </div>
    )
}

export default SearchByWord;

{/*<label className='searchByWord__label'>Введите слово, по которому хотите осуществить поиск</label> */
}