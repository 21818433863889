import './Login.css'
import React from 'react'
import SignInForm from '../../components/SignInForm/SignInForm'

function Login ({onSubmit, errorMessage, error}) {

  return (
    <section className="signInPage">

      <div className='signInPage__back-up'>
        <div className='signInPage__back-up-wrapper'>
          <h2 className='signInPage__title'>Добро пожаловать в трeкер задач</h2>
          <div className='signInPage__component'>
            <SignInForm
              onSubmit={onSubmit}
              error={error}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>

      <div className='signInPage__pic'></div>

    </section>
  );
}

export default Login;