import UnapprovedTasksTable from '../../components/UnapprovedTasksPage/UnapprovedTasksTable'
import './UnapprovedTasksPage.css'

function UnapprovedTasksPage({openIsUnapprovePopup, unapprovedTasks, openDeletePopup}) {
    
    return(
        <section className='unapprovedTasksPage-main-container'>
            {unapprovedTasks.length?
            
            <UnapprovedTasksTable 
                unapprovedTasks={unapprovedTasks}
                openIsUnapprovePopup={openIsUnapprovePopup}
                openDeletePopup={openDeletePopup}
            />
            :
            <h3 className='unapprovedTasksPage-message'>Нет задач на согласование</h3>
            }
        </section>
    )
}

export default UnapprovedTasksPage;