import {Link} from 'react-router-dom'
import './UnapprovedTask.css'

function UnapprovedTask({item, openIsUnapprovePopup, openDeletePopup}) {
    
    const handleApproveClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        openIsUnapprovePopup(item.task_id)
    };

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        openDeletePopup(item.task_id)
    };

    return(
        <>
        <Link to={`/task/${item.task_id}`} className="one-task__link-main-wrapper">
        <ul className="one-task-ul">

            <li className="one-task-container">
                <p className="one-task__text">{item.task_id}</p>
            </li>
            
            <li className="one-task-container-name">
                <p className="one-task__text">{item.name}</p>
            </li>

            <li className="one-task-container">
                {item.owner == null? <p className="one-task__text"></p> : <p className="one-task__text">{item.owner.name}</p>}
            </li>

            <li className="one-task-container-text">
                <p className="one-task__text">{item.text}</p>
            </li>

            <li className="one-task-container one-task-container_button" >
                <button 
                    onClick={handleClick} 
                    className="one-task-btn-unapprove"
                >
                    Отменить
                </button>
            </li>

            <li className="one-task-container one-task-container_button" >
                <button 
                    onClick={handleApproveClick}
                    className="one-task-btn-approve"
                >
                    Согласовано
                </button>
            </li>
            
        </ul>
            
        </Link>
        
        </>
    )
}

export default UnapprovedTask;


//if user.canconfirm = true