import { useEffect } from 'react';
import CreateTaskForm from '../../components/CreateTaskPage/CreateTaskForm'

function CreateTaskPage({ createTask, getDepartments, departments }) {

    useEffect(()=> {
        getDepartments()
    }, [])

    return (
        <>
        <CreateTaskForm 
            createTask={createTask}
            departments={departments}
        />
        </>
    )
}

export default CreateTaskPage;
