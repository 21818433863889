import ShowFilter from '../../components/TasksStatuses/ShowFilter/ShowFilter'

import './MyPage.css'
import {useEffect, useState} from "react";
import {TasksColumn} from "../../components/TasksStatuses/TasksColumn/TasksColumn";

function MyPage({
                    tasksOnKanbanToRender,
                    showApprivedTasksKanban,
                    showUnApprivedTasksKanban,
                    showAllTasksKanban,
                    update
                }) {
    const [search, setSearch] = useState('');
    const [taskToImplementation, setTaskToImplementation] = useState([]);
    const [inProgress, setInProgress] = useState([]);
    const [internalTesting, setInternalTesting] = useState([]);
    const [customerTesting, setCustomerTesting] = useState([]);
    const [onPause, setOnPause] = useState([]);
    const [done, setDone] = useState([]);
    const [columns, setColumns] = useState([
        {title: "Создана", tasks: taskToImplementation},
        {title: "В работе", tasks: inProgress},
        {title: "Тестирование внутреннее", tasks: internalTesting},
        {title: "Тестирование клиентом", tasks: customerTesting},
        {title: "На паузе", tasks: onPause}
    ]);
    useEffect(()=>window.scrollTo(0, 0), [])
    useEffect(() => {
        let currentTasksOnKanbanToRender;
        if (search.length === 0) {
            currentTasksOnKanbanToRender = tasksOnKanbanToRender;
        } else {
            currentTasksOnKanbanToRender = tasksOnKanbanToRender.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.text.toLowerCase().includes(search.toLowerCase()) ||
                item.task_id.toString().toLowerCase().includes(search.toLowerCase())
            );
        }

        const newTaskToImplementation = currentTasksOnKanbanToRender.filter((item) => item.status === 'Создана');
        const newInProgress = currentTasksOnKanbanToRender.filter((item) => item.status === 'В работе');
        const newInternalTesting = currentTasksOnKanbanToRender.filter((item) => item.status === "Тестирование внутреннее");
        const newCustomerTesting = currentTasksOnKanbanToRender.filter((item) => item.status === "Тестирование клиентом");
        const newOnPause = currentTasksOnKanbanToRender.filter((item) => item.status === "На паузе");
        const newDone = currentTasksOnKanbanToRender.filter((item) => item.status === "Выполнена");

        setTaskToImplementation(newTaskToImplementation);
        setInProgress(newInProgress);
        setInternalTesting(newInternalTesting);
        setCustomerTesting(newCustomerTesting);
        setOnPause(newOnPause);
        setDone(newDone);

        setColumns([
            {title: "Создана", tasks: newTaskToImplementation},
            {title: "В работе", tasks: newInProgress},
            {title: "Тестирование внутреннее", tasks: newInternalTesting},
            {title: "Тестирование клиентом", tasks: newCustomerTesting},
            {title: "На паузе", tasks: newOnPause},
            {title: "Выполнено", tasks: newDone}
        ]);
    }, [search, tasksOnKanbanToRender]);



    return (
        <section className="tasks-page-full-container">
            <ShowFilter
                update={update}
                setSearch={(value) => setSearch(value)}
                searchValue={search}
                showApprivedTasksKanban={showApprivedTasksKanban}
                showUnApprivedTasksKanban={showUnApprivedTasksKanban}
                showAllTasksKanban={showAllTasksKanban}
            />
            <section className="tasks-page-kanban-container">
                {columns.map((el, index) => (
                    <TasksColumn
                        keyWord={search}
                        key={index}
                        board={el}
                    />
                ))}
            </section>
        </section>
    );
}

export default MyPage;

//<Canceled canceled={canceled}/>
//<Done done={done}/>