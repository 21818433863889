import './FilterByQuantity.css'
import {TransparentButton} from "../../../TransparentButton/TransparentButton";

function FilterByQuantity({limit, addTasks, tasksAfterSearch, changeSetNum, changeCurrentPage, changeActivePage}) {

    function handleChangeTwentyLimit() {
        addTasks(20)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }

    function handleChangeFiftyLimit() {
        addTasks(50)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }

    function handleChangeOneHundredLimit() {
        addTasks(100)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }

    function handleChangeAll() {
        addTasks(tasksAfterSearch.length)
        changeSetNum(1)
        changeCurrentPage(0)
        changeActivePage(1)
    }


    return(
        <div className="filterByQuantity-main-btn-container">
            <p className="filterByQuantity-title">Вывод задач по:</p>
            <div className="filterByQuantity-btn-container">
                <TransparentButton active={limit===20} onClick={handleChangeTwentyLimit}>20</TransparentButton>
                <TransparentButton active={limit===50} onClick={handleChangeFiftyLimit}>50</TransparentButton>
                <TransparentButton active={limit===100} onClick={handleChangeOneHundredLimit}>100</TransparentButton>
                <TransparentButton active={limit===tasksAfterSearch.length} onClick={handleChangeAll}>Все</TransparentButton>
            </div>
        </div>
    )
}

export default FilterByQuantity;