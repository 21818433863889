import SearchByWord from './SearchByWord/SearchByWord'
import FilterByQuantity from './FilterByQuantity/FilterByQuantity'
import React, {useMemo} from "react";
import {useFilterContext} from "../../../contexts/filters_context";

function Searching({limit, changeCurrentPage, changeActivePage, changeSetNum, onOpenSettingsPopup, searchByKeyWord, onShowAll, addTasks, tasksAfterSearch, update, setKeyWord, keyWord, showAll}) {
    const { filters, setFilters } = useFilterContext();
    const checkFilters = ()=>{
        let countFilters = 0
        Object.values(filters).forEach(el=>{
            if(el.length>0){
                countFilters++
            }
        })
        return countFilters
    }
    const countFilters = useMemo(()=>{
        let countFilters = 0
        Object.values(filters).forEach(el=>{
            if(el.length>0){
                countFilters++
            }
        })
        return countFilters},[filters])
    const handleReset = () => {
        setFilters({
            statusKeyWord: '',
            initiatorKeyWord: '',
            taskDateKeyWord: '',
            finishTaskDateKeyWord: '',
            deadlineKeyWord: '',
            finishDeadlineKeyWord: ''
        });
        showAll();
    }
    return (
        <section className='searching__main-container'>

            <SearchByWord
                setKeyWord={setKeyWord}
                keyWord={keyWord}
                update={update}
                searchByKeyWord={searchByKeyWord} 
                onShowAll={onShowAll}
                changeSetNum={changeSetNum}

                changeCurrentPage={changeCurrentPage}
                changeActivePage={changeActivePage}
            />
            <FilterByQuantity
                addTasks={addTasks} 
                tasksAfterSearch={tasksAfterSearch}
                changeSetNum={changeSetNum}

                changeCurrentPage={changeCurrentPage}
                changeActivePage={changeActivePage}
                limit={limit}
            />

            <div className="searching__settings-btn_container">
                {countFilters!==0 &&  <button className="clean_filters" type='button' onClick={handleReset}>Сбросить</button>}
                <button className='searching__settings-btn' title="Фильтры" onClick={onOpenSettingsPopup}></button>
                {countFilters!==0 && <div className="count_filters">{countFilters}</div>}
            </div>

            
        </section>
    )
}

export default Searching;