import UnapprovedTask from './UnapprovedTask'
import './UnapprovedTasksTable.css'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function UnapprovedTasksTable({unapprovedTasks, openIsUnapprovePopup, openDeletePopup}) {
  
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem("currentUser"))
  const [message, setMessage] = React.useState('')
 
  React.useEffect(() => {
    if( currentUser.can_confirm_all == false && currentUser.department.length < 1 ){
      setMessage('У вас нет прав на согласование')
      navigate('/')
    }
  },[]);

    return(
      <section className='taskTable__section'>

        {currentUser.can_confirm_all || unapprovedTasks.length?
        <>
          <ul className='taskTable__name-container'>
            <li className='taskTable__status'key='2'>Номер задачи</li>
            <li className='taskTable__name'key='1'>Название</li> 
            <li className='taskTable__status'key='4'>Инициатор</li>
            <li className='taskTable__text'key='5'>Описание</li>
            <li className='taskTable__status'key='6'></li>
            <li className='taskTable__status'key='7'></li>
          </ul>

          <>
            {unapprovedTasks.map((item) => (
              <UnapprovedTask 
                key={item.task_id}
                item={item}
                openIsUnapprovePopup={openIsUnapprovePopup}
                openDeletePopup={openDeletePopup}
              />
            ))}
          </>
        </>
        :
        <h3 className='taskTable__message'>{message}</h3>
      }

      </section>

    )
}

export default UnapprovedTasksTable;