import Task from '../Task/Task'
import '../Tasks.css'

export function TasksColumn({board,keyWord}) {
    return (
        <div
            className='tasks-section'
        >
            <h3 className='tasks-title'>{board.title}</h3>
            <ul className='tasks-container'>
                {board.tasks.map((item) => (
                    <Task
                        keyWord={keyWord}
                        key={item.task_id}
                        item={item}
                    />
                ))}
            </ul>
        </div>
    );
}
