import React, { useEffect, useState } from 'react';
import TaskTable from '../../components/AllTasksPage/TaskTable/TaskTable';
import Searching from '../../components/AllTasksPage/Searching/Searching';
import SettingsPopup from '../../components/Popups/SettingsPopup/SettingsPopup';

import './AllTasksPage.css';
import {useFilterContext} from "../../contexts/filters_context";

function AllTasksPage({ tasks, onOpenSettingsPopup, addTasks, limit, isSettingsPopup, closeAllPopups, initiators, update }) {
    const [keyWord, setKeyWord] = useState('');
    const [tasksAfterSearch, setTasksAfterSearch] = useState(tasks);
    const [myNum, setNum] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [isFilters, setIsFilters] = useState(false);

    const { filters } = useFilterContext();

    useEffect(() => {
        filterByKeyWords(
            filters.taskDateKeyWord,
            filters.finishTaskDateKeyWord,
            filters.initiatorKeyWord,
            filters.statusKeyWord,
            filters.deadlineKeyWord,
            filters.finishDeadlineKeyWord
        );
    }, [tasks]);

    const changeSetNum = (newMeaning) => setNum(newMeaning);
    const changeActivePage = (page) => setActivePage(page);
    const changeCurrentPage = (page) => setCurrentPage(page);

    const searchByKeyWord = (keyWord) => {
        const keywordLowerCase = keyWord.toLowerCase();
        setTasksAfterSearch(tasks.filter((item) =>
            item.name.toLowerCase().includes(keywordLowerCase) ||
            item.text.toLowerCase().includes(keywordLowerCase) ||
            item.task_id.toString().includes(keywordLowerCase)
        ));
    };

    const filterByKeyWords = (taskDateKeyWord, finishTaskDateKeyWord, initiatorKeyWord, statusKeyWord, deadlineKeyWord, finishDeadlineKeyWord) => {
        setTasksAfterSearch(
            tasks.filter((item) =>
                ((Date.parse(item.taskDate) <= finishTaskDateKeyWord && Date.parse(item.taskDate) >= taskDateKeyWord) || (taskDateKeyWord === '' && finishTaskDateKeyWord === '')) &&
                (item.owner == null || item.owner.name === initiatorKeyWord || initiatorKeyWord === '') &&
                (item.status === statusKeyWord || statusKeyWord === '') &&
                ((Date.parse(item.deadline) >= deadlineKeyWord && Date.parse(item.deadline) <= finishDeadlineKeyWord) || (deadlineKeyWord === '' && finishDeadlineKeyWord === ''))
            )
        );

        closeAllPopups();
    };

    const showAll = () => setTasksAfterSearch(tasks);

    return (
        <section className='allTasksPage__section'>
            <Searching
                keyWord={keyWord}
                setKeyWord={setKeyWord}
                update={update}
                searchByKeyWord={searchByKeyWord}
                onShowAll={showAll}
                addTasks={addTasks}
                tasksAfterSearch={tasksAfterSearch}
                onOpenSettingsPopup={onOpenSettingsPopup}
                changeSetNum={changeSetNum}
                changeCurrentPage={changeCurrentPage}
                changeActivePage={changeActivePage}
                limit={limit}
                showAll={showAll}
            />
            {isFilters ? <p>Фильтры: {Object.values(filters).filter(Boolean).join(', ')}</p> : null}
            <TaskTable
                tasksAfterSearch={tasksAfterSearch}
                limit={limit}
                keyWord={keyWord}
                changeSetNum={changeSetNum}
                myNum={myNum}
                currentPage={currentPage}
                activePage={activePage}
                changeActivePage={changeActivePage}
                changeCurrentPage={changeCurrentPage}
            />
            <SettingsPopup
                isOpen={isSettingsPopup}
                onClose={closeAllPopups}
                initiators={initiators}
                filterByKeyWords={filterByKeyWords}
                changeSetNum={changeSetNum}
                changeCurrentPage={changeCurrentPage}
                changeActivePage={changeActivePage}
                showAll={showAll}
            />
        </section>
    );
}

export default AllTasksPage;
