import './Task.css'
import { Link } from 'react-router-dom';

function highlightTextWithHTML(text, keyword) {
    if (!text) {
        return { __html: '' }; // Возвращаем пустую строку, если text не определен
    }
    const regex = new RegExp(`(${keyword})`, 'gi');
    const highlightedText = text.replace(regex, '<span class="highlight">$1</span>');
    return { __html: highlightedText };
}

function OneTask({ item, keyWord }) {
    const date = new Date(item.deadline);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const message = '';
    const formattedDate = date.toLocaleDateString("ru-RU", options) === '1 января 1 г.' ? message : date.toLocaleDateString("ru-RU", options);

    const shorterText = () => {
        if (item.text.length > 300) {
            return item.text.slice(0, 300).concat("...");
        }
        return item.text; // Возвращаем оригинальный текст, если его длина менее 300 символов
    };

    return (
        <Link to={`/task/${item.task_id}`} className="one-task__link-main-wrapper">
            {item.confirm ?
                <p className="one-task__status-confirm">Согласовано</p> :
                <p className="one-task__status-unconfirm">Не согласовано</p>
            }
            <ul className="one-task-ul">
                <li className="one-task-container">
                    <p className="one-task__text" dangerouslySetInnerHTML={highlightTextWithHTML(String(item.task_id), keyWord)}></p>
                </li>
                <li className="one-task-container-name">
                    <p className="one-task__text" dangerouslySetInnerHTML={highlightTextWithHTML(String(item.name), keyWord)}></p>
                </li>
                <li className="one-task-container">
                    <p className="one-task__text">{item.status}</p>
                </li>
                <li className="one-task-container">
                    {item.owner == null ? <p className="one-task__text"></p> : <p className="one-task__text">{item.owner.name}</p>}
                </li>
                <li className="one-task-container">
                    {item.analyst == null ?
                        <p className="one-task__text"></p> :
                        <p className="one-task__text">{item.analyst.name}</p>}
                </li>
                <li className="one-task-container-text">
                    <p className="one-task__text" dangerouslySetInnerHTML={highlightTextWithHTML(shorterText(), keyWord)}></p>
                </li>
                <li className="one-task-container-data">
                    <p className="one-task__text">{formattedDate}</p>
                </li>
            </ul>
        </Link>
    );
}

export default OneTask;
