import './Loading.css'

function Loading({loading}) {
    return(
        <>
        {loading?
            <div className='loading__opened'>
                <h1 className='loading__title'>Получение данных с сервера ...</h1>
            </div>
        :
            <></>
        }

        </>
    )
}

export default Loading;