import React from "react";
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const ProtectedRoute = ({
  isLoggin,
  children,
}) => {

  const location = useLocation()
 const token = localStorage.getItem("token")

  if (!isLoggin || isLoggin == null) {
    <Navigate to='/sign-in' replace />;
  }
  
  if (isLoggin && location.pathname === '/sign-in') {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default ProtectedRoute