import React from 'react'
import '../Popups.css'

function InfoPopup({ onClose, isOpen, message }) {

return (
    
  <div className={`unapprovePopup ${isOpen && 'unapprovePopup__opened'}`}>

    <div className="infoPopup__container">
      <button 
        className="unapprovePopup__close-button" 
        type="button" 
        onClick={onClose}>
      </button>
      <h2 className="infoPopup__message">{message}</h2>
    </div>
  </div>
)
}

export default InfoPopup;