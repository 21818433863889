import React from 'react'
import '../Popups.css'
import {TransparentButton} from "../../TransparentButton/TransparentButton";

function UnapprovePopup({onClose, isOpen, taskId, approveTask}) {

    function handleApproveTask() {
        approveTask(taskId)
    }

    return (

        <div className={`unapprovePopup ${isOpen && 'unapprovePopup__opened'}`}>

            <div className="unapprovePopup__container">
                <button
                    className="unapprovePopup__close-button"
                    type="button"
                    onClick={onClose}>
                </button>
                <h2 className="unapprovePopup__title">Вы подтверждаете согласование?</h2>
                <div className="unapprovePopup__button-container">
                    <TransparentButton
                        onClick={handleApproveTask}
                    >
                        Да, согласовать
                    </TransparentButton>
                    <TransparentButton
                        onClick={onClose}
                    >
                        Нет, отменить
                    </TransparentButton>
                </div>

            </div>
        </div>
    )
}

export default UnapprovePopup;