import './SignInForm.css'
import React from 'react'

function Login({onSubmit, error, errorMessage}) {
  const [isFormValid, setIsFormValid] = React.useState(false)
  
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [errorUsernameMessage, setErrorUsernameMessage] = React.useState('')
  const [errorPasswordMessage, setErrorPasswordMessage] = React.useState('')

  const [errorUsername, setErrorUsername] = React.useState(true)
  const [errorPassword, setErrorPassword] = React.useState(true)

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit({
      username,
      password
    });
  }

  const handlePasswordChange = (e) => {
    if (!e.target.value.length) {
      setErrorPasswordMessage('Введите пароль');
      setErrorPassword(true);
    }else {
      setErrorPasswordMessage('');
      setErrorPassword(false);
    }
      setPassword(e.target.value);
  }
  
  const handleEmailChange = (e) => {
    if (!e.target.value.length) {
      setErrorUsernameMessage('Введите свое имя пользователя')
      setErrorUsername(true)
    }else {
      setErrorUsernameMessage('')
      setErrorUsername(false)
    }
    setUsername(e.target.value)
  }
  
  React.useEffect(() => {
    if (errorUsername || errorPassword) {
      setIsFormValid(false)
    } else {
      setIsFormValid(true)
    }
  }, [errorUsername, errorPassword])

  return (
    <section className="signIn">
        
        <form className="signIn__form" onSubmit={handleSubmit}>
        <h3 className="signIn__title">Вход</h3>
        <fieldset className='signIn__fieldset'>
          <span className='signIn__input-span'>Имя пользователя</span>
            <input
              required
              name="username"
              type="text"
              minLength="1"
              className="signIn__input" 
              onChange={handleEmailChange}
            />  
          {errorUsername? <span className="contactForm__err-span">{errorUsernameMessage}</span>:<span></span>}

          <span className="signIn__input-span">Пароль</span>
          <input
              required
              name="password"
              type="password"
              minLength="4"
              className="signIn__input"
              onChange={handlePasswordChange}
          ></input>
          {errorPassword? <span className="contactForm__err-span">{errorPasswordMessage}</span>:<span></span>}

          </fieldset>
          <div className="signIn__btn-container">
            {error? <span className="signIn__under-btn-err-span">{errorMessage}</span>: <span></span>}
            <button 
              type="submit" 
              disabled={!isFormValid}
              className={`signIn__btn ${isFormValid && 'signIn__btn_active'}`}>
              {'Войти'}
            </button>
          </div>
        </form>
      </section>
  );
}

export default Login;