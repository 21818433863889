import React from 'react';
import '../Popups.css';
import { TransparentButton } from "../../TransparentButton/TransparentButton";
import {useFilterContext} from "../../../contexts/filters_context";
import {useNavigate} from "react-router-dom";

function SettingsPopup({ showAll, onClose, isOpen, filterByKeyWords, initiators, changeSetNum, changeCurrentPage, changeActivePage }) {
    const navigate = useNavigate()
    const { filters, setFilters } = useFilterContext();

    const handleStatusChange = (e) => setFilters(prev => ({ ...prev, statusKeyWord: e.target.value }));
    const handleInitiatorChange = (e) => {
        setFilters(prev => ({...prev, initiatorKeyWord: e.target.value}));
    }
    const handleTaskDateChange = (e) => setFilters(prev => ({ ...prev, taskDateKeyWord: e.target.value }));
    const handleFinishTaskDateChange = (e) => setFilters(prev => ({ ...prev, finishTaskDateKeyWord: e.target.value }));
    const handleTaskDeadlineChange = (e) => setFilters(prev => ({ ...prev, deadlineKeyWord: e.target.value }));
    const handleFinishTaskDeadlineChange = (e) => setFilters(prev => ({ ...prev, finishDeadlineKeyWord: e.target.value }));

    const handleFilterByKeyWord = (e) => {
        e.preventDefault();
        changeSetNum(1);
        changeCurrentPage(0);
        changeActivePage(1);
        const taskDateTimestamp = filters.taskDateKeyWord ? Date.parse(filters.taskDateKeyWord) : '';
        const finishTaskDateTimestamp = filters.finishTaskDateKeyWord ? Date.parse(filters.finishTaskDateKeyWord) : '';
        const deadlineTimestamp = filters.deadlineKeyWord ? Date.parse(filters.deadlineKeyWord) : '';
        const finishDeadlineTimestamp = filters.finishDeadlineKeyWord ? Date.parse(filters.finishDeadlineKeyWord) : '';

        filterByKeyWords(taskDateTimestamp, finishTaskDateTimestamp, filters.initiatorKeyWord, filters.statusKeyWord, deadlineTimestamp, finishDeadlineTimestamp);
        navigate("/task-list/1")
    }

    const handleReset = () => {
        setFilters({
            statusKeyWord: '',
            initiatorKeyWord: '',
            taskDateKeyWord: '',
            finishTaskDateKeyWord: '',
            deadlineKeyWord: '',
            finishDeadlineKeyWord: ''
        });
        showAll();
    }

    return (
        <aside className={`filter__popup ${isOpen && 'filter__popup__opened'}`}>
            <div className='filter__popup__container'>
                <button className='filter__popup__closebtn' onClick={onClose}></button>
                <form className='filter__popup__form' onSubmit={handleFilterByKeyWord}>
                    <label className='filter__popup__inputname'>Hачало диапазона создания задачи</label>
                    <div className='filter__popup__input-container'>
                        <input onChange={handleTaskDateChange} className='filter__popup__input' type="date" value={filters.taskDateKeyWord} />
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, taskDateKeyWord: '' }))}></button>
                    </div>
                    <label className='filter__popup__inputname'>Окончание диапазона создания задачи</label>
                    <div className='filter__popup__input-container'>
                        <input onChange={handleFinishTaskDateChange} className='filter__popup__input' type="date" value={filters.finishTaskDateKeyWord} />
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, finishTaskDateKeyWord: '' }))}></button>
                    </div>
                    <label className='filter__popup__inputname'>Начало диапазона дат дедлайна</label>
                    <div className='filter__popup__input-container'>
                        <input onChange={handleTaskDeadlineChange} className='filter__popup__input' type="date" value={filters.deadlineKeyWord} />
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, deadlineKeyWord: '' }))}></button>
                    </div>
                    <label className='filter__popup__inputname'>Окончание диапазона дат дедлайна</label>
                    <div className='filter__popup__input-container'>
                        <input onChange={handleFinishTaskDeadlineChange} className='filter__popup__input' type="date" value={filters.finishDeadlineKeyWord} />
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, finishDeadlineKeyWord: '' }))}></button>
                    </div>
                    <div className='filter__popup__input-container'>
                        <select onChange={handleStatusChange} className='filter__popup__select' value={filters.statusKeyWord}>
                            <option value="">Все статусы</option>
                            <option value='Создана'>Создана</option>
                            <option value='В работе'>В работе</option>
                            <option value='Тестирование внутреннее'>Тестирование внутреннее</option>
                            <option value='Тестирование клиентом'>Тестирование клиентом</option>
                            <option value='Выполнена'>Выполнена</option>
                            <option value='Закрыта'>Закрыта</option>
                            <option value='На паузе'>На паузе</option>
                            <option value='Отменена'>Отменена</option>
                        </select>
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, statusKeyWord: '' }))}></button>
                    </div>
                    <div className='filter__popup__input-container'>
                        <select onChange={handleInitiatorChange} className='filter__popup__select' value={filters.initiatorKeyWord}>
                            <option value="">Все инициаторы</option>
                            {initiators.map((i, index) => (
                                <option value={i} key={index}>{i}</option>
                            ))}
                        </select>
                        <button className='filter__popup__input-reset-btn' type='button' onClick={() => setFilters(prev => ({ ...prev, initiatorKeyWord: '' }))}></button>
                    </div>
                    <div className='filter__popup__filter-btn-container'>
                        <TransparentButton type='submit'>Фильтровать</TransparentButton>
                        <TransparentButton type='button' onClick={handleReset}>Сбросить все фильтры</TransparentButton>
                    </div>
                </form>
            </div>
        </aside>
    );
}

export default SettingsPopup;
