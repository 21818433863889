import './ShowFilter.css'
import React from 'react'
import {TransparentButton} from "../../TransparentButton/TransparentButton";

function ShowFilter({showApprivedTasksKanban, showUnApprivedTasksKanban, showAllTasksKanban, setSearch, searchValue, update}) {
    const [showOnlyApproved, setShowOnlyApproved] = React.useState(false);

    const handleCheckboxChange = () => {
        setShowOnlyApproved(!showOnlyApproved);
    };

    React.useEffect(() => {
        if (!showOnlyApproved) {
            showAllTasksKanban();
        } else {
            showApprivedTasksKanban();
        }
    }, [showOnlyApproved]);

    const changeSearchValue = (value) => {
        setSearch(value)
    }
    return (
        <div className="showFilter__btn-container">
            <div className="showFilter__container_search">
                <input onChange={(e) => changeSearchValue(e.target.value)} value={searchValue} className="searching__input"
                       placeholder="Введите слово для поиска"/>
                <TransparentButton onClick={update}>Обновить</TransparentButton>
            </div>
            {/*<label className="showFilter__label">*/}
            {/*    <input*/}
            {/*        type="checkbox"*/}
            {/*        onChange={handleCheckboxChange}*/}
            {/*        checked={showOnlyApproved}*/}
            {/*        className="showFilter__btn"*/}
            {/*    />*/}
            {/*    Только согласованные*/}
            {/*</label>*/}
            <div className="input">
                <input
                    onChange={handleCheckboxChange}
                    checked={showOnlyApproved}
                    //onChange={(e) => setShowOnlyApproved(e.target.checked)}
                    type="checkbox"
                    id="switch"/>
                <label htmlFor="switch">
                    <div className="switch"></div>
                    Только согласованные
                </label>
            </div>
        </div>
    );
}

export default ShowFilter;

/*
<button className="showFilter__btn" onClick={showApprivedTasksKanban}>Показать только согласованные</button>
<button className="showFilter__btn" onClick={showUnApprivedTasksKanban}>Показать не согласованные</button>
<button className="showFilter__btn" onClick={showAllTasksKanban}>Показать все</button>
*/