import logo from '../../images/headerlogo.svg'
import './Footer.css'
import { FaYoutube, FaTelegram, FaVk } from 'react-icons/fa'

function Footer() {
  let currentDate = new Date();
  let year = currentDate.getFullYear()

  return (
    <div className="footer">
      <div className="footer__up">  
        <img src={logo} className="footer__logo"></img>
        <ul className="footer__up-contacts">
            <li className="footer__up-contact"><a href="tel:+74951449516">+7 495 14 495 16</a></li>
            <li className="footer__up-contact"><a href="https://e.mail.ru/compose?to=info@veleres.ru" target="_blank">info@veleres.ru</a></li>
        </ul>
      </div>

      <div className="footer__down">
        <p className="footer__down-copyright">Copyright Veles Reserch {year}</p>
        {/*<ul className="footer__up-icons-container">*/}
        {/*  <li className="footer__up-icon footer__up-icon-vk">*/}
        {/*    <FaVk />*/}
        {/*  </li>*/}
        {/*  <li className="footer__up-icon footer__up-icon-telegram">*/}
        {/*    <FaTelegram />*/}
        {/*  </li>*/}
        {/*  <li className="footer__up-icon footer__up-icon-utube">*/}
        {/*    <FaYoutube />*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </div>  
    </div>
  );
}

export default Footer;

//<li className="footer__up-contact">101000, Россия, г. Москва, вн.тер.г. Муниципальный округ басманный, ул. Мясницкая, д. 24/7, стр. 1, помещ. 1/12а</li>

/*
  <li className="footer__up-contact">
    <a className="footer__up-contact-link" href='https://veleres.ru/' target='blank'>https://veleres.ru/</a>
  </li>
*/