import React from 'react'
import '../Popups.css'
import {TransparentButton} from "../../TransparentButton/TransparentButton";

function DeletePopup({onClose, isOpen, taskId, deleteTask}) {

    function handleDeleteTask() {
        deleteTask(taskId)
    }    

return (
    
    <div className={`unapprovePopup ${isOpen && 'unapprovePopup__opened'}`}>

    <div className="unapprovePopup__container">
    <button 
      className="unapprovePopup__close-button" 
      type="button" 
      onClick={onClose}>
    </button>
    <h2 className="deletePopup__title">Вы уверены, что хотите отменить задачу?</h2>
    <div className="unapprovePopup__button-container">
        <TransparentButton
            className="unapprovePopup__button"
            onClick={handleDeleteTask}
        >
            Да
        </TransparentButton>
        <TransparentButton
            onClick={onClose}
        >
            Нет
        </TransparentButton>
    </div>
  
    </div>
    </div>
  )
}

export default DeletePopup;