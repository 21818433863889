import React, {useEffect, useState} from 'react'
import './Pogination.css'
import {useNavigate, useParams} from "react-router-dom";
import cn from "classnames";

function Pogination({number, activePage}) {
    const navigate = useNavigate()
    const {page} = useParams();
    const isActive = number === activePage;
    const changePage = () => {
        navigate(`/task-list/${number}`)
        window.scrollTo(0, 0);
    }

    return (
        <li className='pogination__item-btn'>
            <button onClick={changePage}
                    className={cn('pogination__btn', {
                        ['pogination__btn_active']: +page===number})}>{number}</button>
        </li>
    )
}

export default Pogination;