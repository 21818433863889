import React from 'react'
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom'
import logo from '../../images/headerlogo.svg'
import './Header.css'
import cn from "classnames";
import {TransparentButton} from "../TransparentButton/TransparentButton";

function Header({isLoggin, onSignOut, unapprovedTasks}) {
    const navigation = useNavigate()
    const location = useLocation();
    const [scrolling, setScrolling] = React.useState(false);

    //const currentUser = React.useContext(CurrentUserContext)
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))

    function handleSignOut() {
        navigation("/sign-in")
        onSignOut()
    }

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`header ${scrolling ? 'header_scrolling' : ''}`}>
            <div className="header__wrapper">
                <img src={logo} className="header__logo"></img>
                {isLoggin ?
                    <div className="header__container">
                        <nav className="header__nav-container">
                            <NavLink className={({isActive}) => cn("header__link", {
                                "header__link--active": isActive
                            })} to='/'>Доска задач</NavLink>
                            <NavLink className={({isActive}) => cn("header__link", {
                                "header__link--active": isActive || /^\/task-list\/\d+$/.test(location.pathname)
                            })} to='/task-list/1'>Список задач</NavLink>
                            {unapprovedTasks.length ?
                                <NavLink className={({isActive}) => cn("header__link", {
                                    "header__link--active": isActive
                                })} to='/unapproved-tasks'>Задачи к согласованию</NavLink>
                                :
                                <></>
                            }
                            <NavLink className={({isActive}) => cn("header__link", {
                                "header__link--active": isActive
                            })} to='/create-task'>Создать задачу</NavLink>
                        </nav>
                        <div className="header__btn-and-greeting-container">
                            <h3 className="header__greeting">Здравствуйте, {currentUser.first_name}</h3>
                            <TransparentButton onClick={handleSignOut}>Выйти</TransparentButton>
                            {/*<Link to={`/sign-in`} className="header__btn" onClick={handleSignOut}>Выйти</Link>*/}
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );
}

export default Header;