import './CreateTaskForm.css'
import React from 'react'
import Preloader from '../Preloader/Preloader'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Base64UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve({ default: reader.result });
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            }));
    }

    // Aborts the upload process.
    /*abort() {
        // Implement aborting the upload if necessary
    }*/
}

    function CreateTaskForm({ createTask, departments }) {

    const [text, setHtmlText] = React.useState('')
    const [name, setName] = React.useState('')
    const [selectedDepartmentId, setSelectedDepartmentId] = React.useState('')

    const [errorTextMessage, setErrorTextMessage] = React.useState('')
    const [errorTitleMessage, setErrorTitleMessage] = React.useState('')
    const [errorDepartmentMessage, setErrorDepartmentMessage] = React.useState('')


    const [errorText, setErrorText] = React.useState(true)
    const [errorTitle, setErrorTitle] = React.useState(true)
    const [errorDepartment, setErrorDepartment] = React.useState(true)

    const [isFormValid, setIsFormValid] = React.useState(false)


    function handleChangeTitle(e) {
        if (!e.target.value.length) {
            setErrorTitleMessage('Введите название задачи');
            setErrorTitle(true);
        }else {
            setErrorTitleMessage('');
            setErrorTitle(false);
        }
        setName(e.target.value)
    }

    function handleSelecteDepartment(e) {
        if (!e.target.value.length) {
            setErrorDepartmentMessage('Выберите департамент');
            setErrorDepartment(true);
        }else {
            setErrorDepartmentMessage('');
            setErrorDepartment(false);
        }
        setSelectedDepartmentId(e.target.value)
    }

    function handleCreateTask(e) {
        e.preventDefault();
        createTask(name, text, selectedDepartmentId)

        setName('');
        setHtmlText('');
        setSelectedDepartmentId('');
        setErrorTitle(true)
        setErrorDepartment(true)
        setIsFormValid(false)
    }

    console.log(selectedDepartmentId)

    const onInit = (editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new Base64UploadAdapter(loader);
        };
    };

    React.useEffect(() => {
        if (errorTitle || errorDepartment || errorText) {
          setIsFormValid(false)
        } else {
          setIsFormValid(true)
        }
      }, [errorTitle, errorDepartment, errorText])

    React.useEffect(() => {
        if (departments.length === 1) {
            setSelectedDepartmentId(departments[0].id);
            setErrorDepartmentMessage('');
            setErrorDepartment(false);
        }
    }, [departments, name]);

        return (
            <div className="createTaskForm-main-container">
                <form className='createTaskForm__form' onSubmit={handleCreateTask}>
                    <h2 className="createTaskForm-main-title">Создать задачу</h2>    
                    
                    <div className='createTaskForm__select-and-input-container'>
                        {departments.length > 1 ?
                        <div className="createTaskForm-main-input-container">
                            <select 
                                value={selectedDepartmentId}
                                className={`createTaskForm__select ${!errorDepartment && 'createTaskForm__select_valid'}`}
                                onChange={handleSelecteDepartment}
                            >
                                <option value="">Выберите департамент</option>
                                    {departments.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                ))}    
                            </select>
                            {errorDepartment? <span className="createTaskForm__err-span">{errorDepartmentMessage}</span>:<span></span>}
                        </div>
                        : <div></div>
                        
                        }

                        <div className="createTaskForm-main-input-container">
                            <input 
                                placeholder='Введите название задачи'
                                className={`createTaskForm-main-input ${!errorTitle && 'createTaskForm-main-input_valid'}`}
                                onChange={handleChangeTitle}
                                type="text"
                                value={name}
                            /> 
                            {errorTitle? <span className="createTaskForm__err-span">{errorTitleMessage}</span>:<span></span>}
                        </div>               
                    </div>


                    <CKEditor
                        editor={ ClassicEditor }
                        //data=""
                        data={text}
                        className="ck-editor__editable"
                            onReady={onInit}
                            onChange={(event, editor) => {
                                //setHtmlText(editor.getData())
                                if (!editor.getData().length) {
                                    setErrorTextMessage('Введите текст задачи');
                                    setErrorText(true);
                                }else {
                                    setErrorTextMessage('');
                                    setErrorText(false);
                                }
                                setHtmlText(editor.getData())
                                console.log(editor.getData())
                            }}
                            
                        
                        className="my-ckeditor-container"
                    />

                    {errorText? <span className="createTaskForm__err-span">{errorTextMessage}</span>:<span></span>}

                    <button
                        className={`createTaskForm-main-btn ${isFormValid && 'createTaskForm-main-btn_active'}`}
                        type='submit'
                        disabled={!isFormValid}
                    >
                        Создать
                    </button>    
                    </form>
                    
            </div>
            );
}


export default CreateTaskForm;
