import { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
    const [filters, setFilters] = useState({
        statusKeyWord: '',
        initiatorKeyWord: '',
        taskDateKeyWord: '',
        finishTaskDateKeyWord: '',
        deadlineKeyWord: '',
        finishDeadlineKeyWord: ''
    });

    return (
        <FilterContext.Provider value={{ filters, setFilters }}>
            {children}
        </FilterContext.Provider>
    );
};
